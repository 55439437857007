import React, { useState, useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { onAuthStateChange } from './api/fb'

import {
  HashRouter as Router,
  Switch,
  Route
} from 'react-router-dom'

import { SignUpForm as Form1 } from './components/1/Form'
import { SignUpFormEmp as EmpForm1 } from './components/1/FormEmp'

import { SignUpForm as Form2 } from './components/2/Form'
import { SignUpFormEmp as EmpForm2 } from './components/2/FormEmp'

import { SignUpForm as Form3 } from './components/3/Form'
// import { SignUpFormEmp as EmpForm3 } from './components/3/FormEmp'

import { SignUpForm as Form4 } from './components/4/Form'
// import { SignUpFormEmp as EmpForm4 } from './components/4/FormEmp'

import { SignUpForm as Form5 } from './components/5/Form'
// import { SignUpFormEmp as EmpForm5 } from './components/5/FormEmp'

import { SignUpForm as Form6 } from './components/6/Form'
// import { SignUpFormEmp as EmpForm6 } from './components/6/FormEmp'

import { Login } from './components/Login'
import { getOrganization } from './api/fb'
import { getString, languages } from './components/utils'

import {ToastsContainer, ToastsStore} from 'react-toasts';
import ReactCountryFlag from "react-country-flag"

import { keys } from 'lodash'

import { Spinner, Row, Col, Input, Button, Container, Nav, Navbar, InputGroup, InputGroupAddon, InputGroupText, NavbarBrand} from 'reactstrap'

const qs = require('query-string')
const logo = require('./assets/rsz_logo-white.png')

const renderFlag = (code) => {
  const style = {
      width: '1em',
      height: '1em',
  }
  
  if (code === "en"){
      return <ReactCountryFlag style={style}
      countryCode="US" svg/>
  }
  else if (code === "zh" || code === "zht"){
      return <ReactCountryFlag style={style}
      countryCode="CN" svg />
  }
  else if (code === "ta"){
      return <ReactCountryFlag style={style}
      countryCode="IN" svg />
  }
  else if (code === "ms"){
      return <ReactCountryFlag style={style}
      countryCode="MY" svg />
  }
  else if (code === "bn"){
      return <ReactCountryFlag style={style}
      countryCode="BD" svg />
  }
}

const InnerRoute = ({location}) => {
  const { dormId = null, lang = null } = qs.parse(location.search)

  const [dorm, setDorm] = useState(null)
  const [formId, setFormId] = useState(null)
  const [dormInput, setDormInput] = useState('')

  const [language, setLanguage] = useState('')

  const [isLoading, setIsLoading] = useState(true)
  const [invalidParam, setInvalidParam] = useState(false)

  useEffect(() => {
    console.log(lang)
    if (lang === null || !languages[lang]){
        setLanguage("en")
    }
    else {
        setLanguage(lang)
    }
}, [lang])

  useEffect(() => {
    const checkDorm = async () => {

        const orgs = await getOrganization(dormId)
        const orgId = keys(orgs).find(o => {
            const { searchID = '' } = orgs[o]

            return searchID.toLowerCase() === dormId.trim().toLowerCase()
        })

        if (orgId){
            const { formId: id } = orgs[orgId]
            setFormId(id)
            setDorm(orgId)
        }
        else {
            setInvalidParam(true)
        }

        setIsLoading(false)
    }
    if (dormId === null){
      setIsLoading(false)
      return
    }

    else {
        setIsLoading(true)
        checkDorm()
    }
  }, [dormId])

  const handleSubmitOrg = async () => {
    setIsLoading(true)

    const orgs = await getOrganization(dormInput)
    const orgId = keys(orgs).find(o => {
        const { searchID = '' } = orgs[o]

        return searchID.toLowerCase() === dormInput.trim().toLowerCase()
    })

    if (orgId){
      const { formId: id } = orgs[orgId]
      setFormId(id)
      setDorm(orgId)
    }

    else {
        ToastsStore.error(getString("errorCode", language), 4000)
    }

    // const orgs = await getOrganization(dormInput)
    // console.log(orgs)

    setIsLoading(false)

  }
  
  const renderForm = () => {
    if (isLoading){
      return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: "100%"}}>
          <Spinner color="info" style={{ width: '5rem', height: '5rem' }}/>
      </div>
    }

    if (invalidParam){
        return <div>Code not found.</div>
    }

    if (dorm === null){
      return <div className="info-div">
              <Row>
                  <Col>
                      <Input disabled={isLoading} value={dormInput} onChange={e => setDormInput(e.target.value)} placeholder={getString("dormId", language)} />
                  </Col>
              </Row>
              <Row>
                  <Col style={{textAlign: "center", marginTop: 30}}>
                      <Button color="primary" disabled={dormInput === "" || isLoading} onClick={handleSubmitOrg}>{getString("submit", language)}</Button>
                  </Col>
              </Row>
          </div>
    }
    else {
      if (formId === 1){
        return <Switch>
          <Route path="/employer" exact render={props => <EmpForm1 {...props} prevLanguage={language} dorm={dorm} formId={formId} /> } />
          <Route path="/" render={props => <Form1 {...props} prevLanguage={language} dorm={dorm} formId={formId} /> } />
        </Switch>
      }
      else if (formId === 2){
        return <Switch>
          <Route path="/employer" exact render={props => <EmpForm2 {...props} prevLanguage={language} dorm={dorm} formId={formId} /> } />
          <Route path="/" render={props => <Form2 {...props} prevLanguage={language} dorm={dorm} formId={formId} /> } />
        </Switch>
      }
      else if (formId === 3){
        return <Switch>
          {/* <Route path="/employer" exact render={props => <EmpForm3 {...props} prevLanguage={language} dorm={dorm} formId={formId} /> } /> */}
          <Route path="/" render={props => <Form3 {...props} prevLanguage={language} dorm={dorm} formId={formId} /> } />
        </Switch>
      }
      else if (formId === 4){
        return <Switch>
          {/* <Route path="/employer" exact render={props => <EmpForm4 {...props} prevLanguage={language} dorm={dorm} formId={formId} /> } /> */}
          <Route path="/" render={props => <Form4 {...props} prevLanguage={language} dorm={dorm} formId={formId} /> } />
        </Switch>
      }
      else if (formId === 5){
        return <Switch>
          {/* <Route path="/employer" exact render={props => <EmpForm5 {...props} prevLanguage={language} dorm={dorm} formId={formId} /> } /> */}
          <Route path="/" render={props => <Form5 {...props} prevLanguage={language} dorm={dorm} formId={formId} /> } />
        </Switch>
      }
      else if (formId === 6){
        return <Switch>
          {/* <Route path="/employer" exact render={props => <EmpForm6 {...props} prevLanguage={language} dorm={dorm} formId={formId} /> } /> */}
          <Route path="/" render={props => <Form6 {...props} prevLanguage={language} dorm={dorm} formId={formId} /> } />
        </Switch>
      }
      else {
        return <div>Not found</div>
      }
    }
  }


  return <Container className="app-container">
    <Navbar color="light" light expand="md" className="default-nav">
        <Nav className="mr-auto">
            <NavbarBrand><img height={30} src={logo} alt="logo"/> </NavbarBrand>
        </Nav>
        <Nav>
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText> {renderFlag(language)} </InputGroupText>
                </InputGroupAddon>
                <Input type="select" value={language} onChange={e => setLanguage(e.target.value)}>
                    {keys(languages).map(value => {
                        const { name = '' } = languages[value]

                        return <option value={value} key={value}>{name} </option>
                    })}                        
                </Input>
            </InputGroup>
        </Nav>
    </Navbar>
    <Container className="form-container">
        {
            renderForm()
        }
    </Container>
  </Container>



} 

function App() {

  const [authUser, setAuthUser] = useState()

  useEffect(() => {
    onAuthStateChange(setAuthUser)
  }, [])

  return <Router>
    <Switch>
      <Route path="/" component={authUser ? InnerRoute : Login} />
    </Switch>
    <ToastsContainer store={ToastsStore} lightBackground />
  </Router> 
}

export default App;
