import React, { useState, useEffect } from 'react'
import { Container, Input, Navbar, NavbarBrand, Nav, Form, Row, Col, Label, FormGroup, FormFeedback, Button, InputGroupAddon, InputGroupText, InputGroup } from 'reactstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { signUp, getCurrentUser } from '../../api/fb'
import { ToastsStore } from 'react-toasts'
import { getString, languages, dormOptions } from '../utils'
import { keys } from 'lodash'
import ReactCountryFlag from "react-country-flag"
import { Download } from '../download'

const logo = require('../../assets/rsz_logo-white.png')
const qs = require('query-string')

const renderFlag = (code) => {
    const style = {
        width: '1em',
        height: '1em',
    }
    
    if (code === "en"){
        return <ReactCountryFlag style={style}
        countryCode="US" svg/>
    }
    else if (code === "zh" || code === "zht"){
        return <ReactCountryFlag style={style}
        countryCode="CN" svg />
    }
    else if (code === "ta"){
        return <ReactCountryFlag style={style}
        countryCode="IN" svg />
    }
    else if (code === "ms"){
        return <ReactCountryFlag style={style}
        countryCode="MY" svg />
    }
    else if (code === "bn"){
        return <ReactCountryFlag style={style}
        countryCode="BD" svg />
    }
}

export const SignUpFormEmp = ({location, prevLanguage, dorm, formId}) => {
    const { lang = null } = qs.parse(location.search)

    // const [dorm, setDorm] = useState(null)
    const [language, setLanguage] = useState(prevLanguage)

    const [isCompleted, setIsCompleted] = useState(false)

    // const [dormInput, setDormInput] = useState('')

    // const [invalidParam, setInvalidParam] = useState(false)

    const [isSubmitting, setIsSubmitting] = useState(false)
    // const [isLoading, setIsLoading] = useState(true)

    const validationSchema = Yup.object().shape({
        companyName: Yup.string().required("required"),
        dormitory: Yup.string().required("required"),
        designation: Yup.string().required("required"),
        firstName: Yup.string().required("required"),
        lastName: Yup.string().required("required"),
        phoneNumber: Yup.string()
            .required("required")
            .matches(/^[0-9]*$/, "errorPhone"),
    })

    // const handleSubmitOrg = async () => {
    //     setIsLoading(true)

    //     const orgs = await getOrganization(dormInput)
    //     const orgId = keys(orgs).find(o => {
    //         const { searchID = '' } = orgs[o]

    //         return searchID.toLowerCase() === dormInput.trim().toLowerCase()
    //     })

    //     if (orgId){
    //         setDorm(orgId)
    //     }

    //     else {
    //         ToastsStore.error(getString("errorCode", language), 4000)
    //     }

    //     // const orgs = await getOrganization(dormInput)
    //     // console.log(orgs)

    //     setIsLoading(false)

    // }

    useEffect(() => {
        if (!prevLanguage){
            if (lang === null || !languages[lang]){
                setLanguage("en")
            }
            else {
                setLanguage(lang)
            }
        }
    }, [lang, prevLanguage])

    // useEffect(() => {
    //     const checkDorm = async () => {

    //         const orgs = await getOrganization(dormId)
    //         const orgId = keys(orgs).find(o => {
    //             const { searchID = '' } = orgs[o]

    //             return searchID.toLowerCase() === dormId.trim().toLowerCase()
    //         })

    //         if (orgId){
    //             setDorm(orgId)
    //         }
    //         else {
    //             setInvalidParam(true)
    //         }

    //         setIsLoading(false)
    //     }

    //     if (dormId === null){
    //         setIsLoading(false)
    //         return
    //     }

    //     else {
    //         setIsLoading(true)
    //         checkDorm()
    //     }

    // }, [dormId])

    const renderForm = () => {
        // if (isLoading){
        //     return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: "100%"}}>
        //         <Spinner color="info" style={{ width: '5rem', height: '5rem' }}/>
        //     </div>
        // }

        // if (invalidParam){
        //     return <div>Code not found.</div>
        // }

        // if (dorm === null){
        //     return <div className="info-div">
        //             <Row>
        //                 <Col>
        //                     <Input disabled={isLoading} value={dormInput} onChange={e => setDormInput(e.target.value)} placeholder={getString("dormId", language)} />
        //                 </Col>
        //             </Row>
        //             <Row>
        //                 <Col style={{textAlign: "center", marginTop: 30}}>
        //                     <Button color="primary" disabled={dormInput === "" || isLoading} onClick={handleSubmitOrg}>{getString("submit", language)}</Button>
        //                 </Col>
        //             </Row>
        //         </div>
        // }

        if (isCompleted){
            return <Download language={language} />
            // return <div>{getString("thank", language)}<br/><br/>{getString("click", language)} <a href="https://www.getknowapp.com/download">KNOW</a> {getString("login", language)}<br/><br/><a href="https://www.getknowapp.com/download"> {getString("link", language)} </a></div>
        }

        const handleSubmitForm = async (values) => {
            setIsSubmitting(true)
            const user = getCurrentUser()

            const payload = {
                anonymousUID: user.uid,
                companyName: values.companyName,
                dormitory: values.dormitory,
                organization: dorm,
                phone: values.phoneNumber,
                countryCode: values.phoneCountryCode,
                firstName: values.firstName,
                lastName: values.lastName,
                designation: values.designation,
                language,
                prefLang: values.prefLang,
                formId
            }


            const { success, message = '' } = await signUp(payload)

            if (success){
                setIsSubmitting(false)
                setIsCompleted(true)
            }
            else {
                ToastsStore.error(message, 4000)
                setIsSubmitting(false)
            }

            // console.log(payload)
            // setTimeout(() => {setIsSubmitting(false); setIsCompleted(true)}, 1000)
            
        }

        return <Formik
            initialValues={{
                companyName: '',
                dormitory: keys(dormOptions)[0],
                firstName: '', 
                lastName: '',
                designation: '',
                phoneCountryCode: '+65',
                phoneNumber: '',
            }}
            onSubmit={handleSubmitForm}
            validationSchema={validationSchema}
            >
                {({values, handleChange, handleBlur, errors, touched, handleSubmit}) => {

                    console.log(values, errors)

                    return <Form onSubmit={handleSubmit}>
                        <h3>{getString("details", language)}</h3>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label> {getString("companyName", language)} </Label>
                                    <Input disabled={isSubmitting} invalid={errors.companyName && touched.companyName} value={values.companyName} onChange={handleChange} onBlur={handleBlur} name="companyName" />
                                    { errors.companyName && touched.companyName ? <FormFeedback>{getString(errors.companyName, language)}</FormFeedback> : null}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>{getString("dormitoryEmp", language)}</Label>
                                    <Input disabled={isSubmitting} invalid={errors.dormitory && touched.dormitory} value={values.dormitory} onChange={handleChange} onBlur={handleBlur} name="dormitory" type="select">
                                        {keys(dormOptions).map(value => <option key={value} value={value}>{value}</option>)}
                                    </Input>
                                    { errors.dormitory && touched.dormitory ? <FormFeedback>{getString(errors.dormitory, language)}</FormFeedback> : null}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label> {getString("firstNameEmp", language)} </Label>
                                    <Input disabled={isSubmitting} invalid={errors.firstName && touched.firstName} value={values.firstName} onChange={handleChange} onBlur={handleBlur} name="firstName" />
                                    { errors.firstName && touched.firstName ? <FormFeedback>{getString(errors.firstName, language)}</FormFeedback> : null}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label> {getString("lastNameEmp", language)} </Label>
                                    <Input disabled={isSubmitting} invalid={errors.lastName && touched.lastName} value={values.lastName} onChange={handleChange} onBlur={handleBlur} name="lastName" />
                                    { errors.lastName && touched.lastName ? <FormFeedback>{getString(errors.lastName, language)}</FormFeedback> : null}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label> {getString("designationEmp", language)} </Label>
                                    <Input disabled={isSubmitting} invalid={errors.designation && touched.designation} value={values.designation} onChange={handleChange} onBlur={handleBlur} name="designation" />
                                    { errors.designation && touched.designation ? <FormFeedback>{getString(errors.designation, language)}</FormFeedback> : null}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>{getString("phoneEmp", language)}</Label>
                                    <Row>
                                        <Col sm={5} md={5} lg={5} xs={5}>
                                            <Input disabled={isSubmitting} type="select" name="phoneCountryCode" value={values.phoneCountryCode} onChange={handleChange} onBlur={handleBlur}>
                                                <option>+60</option>
                                                <option>+62</option>
                                                <option>+65</option>
                                                <option>+66</option>
                                                <option>+91</option>
                                            </Input>
                                        </Col>
                                        <Col sm={7} md={7} lg={7} xs={7}>
                                            <Input disabled={isSubmitting} type="tel" invalid={errors.phoneNumber && touched.phoneNumber} value={values.phoneNumber} onChange={handleChange} onBlur={handleBlur} name="phoneNumber" />
                                            { errors.phoneNumber && touched.phoneNumber ? <FormFeedback>{getString(errors.phoneNumber, language)}</FormFeedback> : null}
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{marginTop: 15}}>
                            <Col style={{textAlign: 'right'}}>
                                <Button type="submit" color="primary" disabled={isSubmitting}>{getString("submit", language)}</Button>
                            </Col>
                        </Row>
                    </Form>
                }}
            </Formik>
    }

    return <Container className="app-container">
        <Navbar color="light" light expand="md" className="default-nav">
            <Nav className="mr-auto">
                <NavbarBrand><img height={30} src={logo} alt="logo"/> </NavbarBrand>
            </Nav>
            <Nav>
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText> {renderFlag(language)} </InputGroupText>
                    </InputGroupAddon>
                    <Input disabled={isSubmitting} type="select" value={language} onChange={e => setLanguage(e.target.value)}>
                        {keys(languages).map(value => {
                            const { name = '' } = languages[value]

                            return <option value={value} key={value}>{name} </option>
                        })}                        
                    </Input>
                </InputGroup>
            </Nav>
        </Navbar>
        <Container className="form-container">
            {
                renderForm()
            }
        </Container>

    </Container>

}