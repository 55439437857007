import React, { useState, useEffect } from 'react'
import { Container, Input, Navbar, NavbarBrand, Nav, Form, Row, Col, Label, FormGroup, FormFeedback, Button, InputGroupAddon, InputGroupText, InputGroup, FormText } from 'reactstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { signUp, getCurrentUser } from '../../api/fb'
import { ToastsStore } from 'react-toasts'
import { getString, languages, religionOptions5, 
    // dietPrefOptions5, unitOptions5, levelOptions5, roomNumberOptions5, 
    prefLangOptions5 } from '../utils'
import { keys } from 'lodash'
import ReactCountryFlag from "react-country-flag"
import { Download } from '../download'
import { LoaderModal } from '../Loader'

const logo = require('../../assets/rsz_logo-white.png')
const qs = require('query-string')

const renderFlag = (code) => {
    // console.log(code)

    const style = {
        width: '1em',
        height: '1em',
    }
    
    if (code === "en"){
        return <ReactCountryFlag style={style}
        countryCode="US" svg/>
    }
    else if (code === "zh" || code === "zht"){
        return <ReactCountryFlag style={style}
        countryCode="CN" svg />
    }
    else if (code === "ta"){
        return <ReactCountryFlag style={style}
        countryCode="IN" svg />
    }
    else if (code === "ms"){
        return <ReactCountryFlag style={style}
        countryCode="MY" svg />
    }
    else if (code === "bn"){
        return <ReactCountryFlag style={style}
        countryCode="BD" svg />
    }
}

export const SignUpForm = ({location, prevLanguage, dorm, formId}) => {
    const { lang = null } = qs.parse(location.search)

    // const [dorm, setDorm] = useState(null)
    const [language, setLanguage] = useState(prevLanguage)

    const [isCompleted, setIsCompleted] = useState(false)
    // const [dormInput, setDormInput] = useState('')

    // const [invalidParam, setInvalidParam] = useState(false)

    const [isSubmitting, setIsSubmitting] = useState(false)
    // const [isLoading, setIsLoading] = useState(true)

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required("required"),
        // lastName: Yup.string().required("required"),
        phoneNumber: Yup.string()
            .required("required")
            .matches(/^[0-9]*$/, "errorPhone"),
        // company: Yup.string().required("required"),
        fin: Yup.string()
            .required("required")
            .matches(/^[A-Za-z]{1}[0-9]{7}[A-Za-z]{1}$/, "errorFullNric"),
        // otherNationality : Yup.string().test("other-nationality-required", "required", function(value) {
        //     if (this.parent.nationality === "Others"){
        //         return value && value.trim() !== ""
        //     }

        //     return true
        // }),
        // otherReligion : Yup.string().test("other-religion-required", "required", function(value) {
        //     if (this.parent.religion === "Others"){
        //         return value && value.trim() !== ""
        //     }

        //     return true
        // }),
        // otherDiet : Yup.string().test("other-diet-required", "required", function(value) {
        //     if (this.parent.dietaryPref === "Others"){
        //         return value && value.trim() !== ""
        //     }

        //     return true
        // }),
        // otherPrefLang : Yup.string().test("other-pref-lang-required", "required", function(value) {
        //     if (this.parent.prefLang === "Others"){
        //         return value && value.trim() !== ""
        //     }

        //     return true
        // }),
    })

    // const handleSubmitOrg = async () => {
    //     setIsLoading(true)

    //     const orgs = await getOrganization(dormInput)
    //     const orgId = keys(orgs).find(o => {
    //         const { searchID = '' } = orgs[o]

    //         return searchID.toLowerCase() === dormInput.trim().toLowerCase()
    //     })

    //     if (orgId){
    //         setDorm(orgId)
    //     }

    //     else {
    //         ToastsStore.error(getString("errorCode", language), 4000)
    //     }

    //     // const orgs = await getOrganization(dormInput)
    //     // console.log(orgs)

    //     setIsLoading(false)

    // }

    useEffect(() => {
        console.log(lang)
        if (!prevLanguage){
            if (lang === null || !languages[lang]){
                setLanguage("en")
            }
            else {
                setLanguage(lang)
            }
        }
    }, [lang, prevLanguage])

    // useEffect(() => {
    //     const checkDorm = async () => {

    //         const orgs = await getOrganization(dormId)
    //         const orgId = keys(orgs).find(o => {
    //             const { searchID = '' } = orgs[o]

    //             return searchID.toLowerCase() === dormId.trim().toLowerCase()
    //         })

    //         if (orgId){
    //             setDorm(orgId)
    //         }
    //         else {
    //             setInvalidParam(true)
    //         }

    //         setIsLoading(false)
    //     }

    //     if (dormId === null){
    //         setIsLoading(false)
    //         return
    //     }

    //     else {
    //         setIsLoading(true)
    //         checkDorm()
    //     }

    // }, [dormId])

    const renderForm = () => {
        // if (isLoading){
        //     return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: "100%"}}>
        //         <Spinner color="info" style={{ width: '5rem', height: '5rem' }}/>
        //     </div>
        // }

        // if (invalidParam){
        //     return <div>Code not found.</div>
        // }

        // if (dorm === null){
        //     return <div className="info-div">
        //             <Row>
        //                 <Col>
        //                     <Input disabled={isLoading} value={dormInput} onChange={e => setDormInput(e.target.value)} placeholder={getString("dormId", language)} />
        //                 </Col>
        //             </Row>
        //             <Row>
        //                 <Col style={{textAlign: "center", marginTop: 30}}>
        //                     <Button color="primary" disabled={dormInput === "" || isLoading} onClick={handleSubmitOrg}>{getString("submit", language)}</Button>
        //                 </Col>
        //             </Row>
        //         </div>
        // }

        if (isCompleted){
            return <Download language={language} />
        }

        const handleSubmitForm = async (values) => {
            setIsSubmitting(true)
            const user = getCurrentUser()

            const payload = {
                anonymousUID: user.uid,
                firstName: values.firstName,
                lastName: ".",
                organization: dorm,
                // unit: values.unit,
                unit: "TSS 5",
                // level: values.level,
                level: "TSS 5",
                // roomNumber: values.roomNumber,
                roomNumber: "TSS 5",
                fin: values.fin,
                // dob: moment(values.dob, "YYYY-MM-DD").format("DD-MMM-YYYY") ,
                // age: values.age + " yrs",
                // gender: values.gender,
                company: "NA",
                // company: values.company,
                // nationality: values.nationality === "Others" ? values.otherNationality : values.nationality,
                // religion: values.religion === "Others" ? values.otherReligion : values.religion,
                // dietaryPref: values.dietaryPref === "Others" ? values.otherDiet : values.dietaryPref,
                phone: values.phoneNumber,
                countryCode: values.phoneCountryCode,
                language,
                // prefLang: values.prefLang === "Others" ? values.otherPrefLang : values.prefLang,
                formId,
            }

            const { success, message = '' } = await signUp(payload)

            if (success){
                setIsSubmitting(false)
                setIsCompleted(true)
            }
            else {
                ToastsStore.error(message, 4000)
                setIsSubmitting(false)
            }

            // console.log(payload)
            // setTimeout(() => {setIsSubmitting(false); setIsCompleted(true)}, 1000)
            
        }

        return <Formik
            initialValues={{
                firstName: '',
                // lastName: '',
                // company:'',
                // unit: unitOptions5[0],
                // level: levelOptions5[0],
                // roomNumber: roomNumberOptions5[0],
                fin: '',
                // dob: "2002-01-01",
                // age: 24,
                // gender: "Male",
                // nationality: nationalityOptions4[0],
                // otherNationality: '',
                // religion: religionOptions5[0],
                // otherReligion: '',
                // dietaryPref: dietPrefOptions5[0],
                // otherDiet: '',
                phoneNumber: '',
                phoneCountryCode: '+65',
                // prefLang: "en",
                // otherPrefLang: "",
            }}
            onSubmit={handleSubmitForm}
            validationSchema={validationSchema}
            >
                {({values, handleChange, handleBlur, errors, touched, handleSubmit, setFieldValue}) => {

                    return <Form onSubmit={handleSubmit}>
                        <h3>{getString("details", language)}</h3>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label> {getString("name", language)} </Label>
                                    <Input disabled={isSubmitting} invalid={errors.firstName && touched.firstName} value={values.firstName} onChange={handleChange} onBlur={handleBlur} name="firstName" />
                                    { errors.firstName && touched.firstName ? <FormFeedback>{getString(errors.firstName, language)}</FormFeedback> : null}
                                </FormGroup>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col>
                                <FormGroup>
                                    <Label>{getString("lastName", language)}</Label>
                                    <Input disabled={isSubmitting} invalid={errors.lastName && touched.lastName} value={values.lastName} onChange={handleChange} onBlur={handleBlur} name="lastName" />
                                    { errors.lastName && touched.lastName ? <FormFeedback>{getString(errors.lastName, language)}</FormFeedback> : null}
                                </FormGroup>
                            </Col>
                        </Row> */}
                        {/* <Row>
                            <Col>
                                <FormGroup>
                                    <Label>{getString("unitNumber", language)}</Label>
                                    <Input disabled={isSubmitting} invalid={errors.unit && touched.unit} value={values.unit} onChange={handleChange} onBlur={handleBlur} name="unit" type="select">
                                        {
                                            unitOptions5.map(unit => <option key={unit} value={unit}>{unit}</option> )
                                        }
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>{getString("level", language)}</Label>
                                    <Input disabled={isSubmitting} invalid={errors.level && touched.level} value={values.level} onChange={handleChange} onBlur={handleBlur} name="level" type="select">
                                        {
                                            levelOptions5.map(level => <option key={level} value={level}>{level}</option> )
                                        }
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>{getString("unit", language)}</Label>
                                    <Input disabled={isSubmitting} invalid={errors.roomNumber && touched.roomNumber} value={values.roomNumber} onChange={handleChange} onBlur={handleBlur} name="roomNumber" type="select">
                                        {
                                            roomNumberOptions5.map(roomNumber => <option key={roomNumber} value={roomNumber}>{roomNumber}</option> )
                                        }
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row> */}
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>{getString("nric", language)}</Label>
                                    <FormText> {getString("nricFullText", language)} </FormText>
                                    <Input disabled={isSubmitting} invalid={errors.fin && touched.fin} value={values.fin} onChange={handleChange} onBlur={handleBlur} name="fin" />
                                    { errors.fin && touched.fin ? <FormFeedback>{getString(errors.fin, language)}</FormFeedback> : null}
                                </FormGroup>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col>
                            <FormGroup>
                                <Label>{getString("dob", language)}</Label>
                                <Input disabled={isSubmitting} invalid={errors.dob && touched.dob} value={values.dob} onChange={handleChange} onBlur={handleBlur} name="dob" type="date" />
                                { errors.dob && touched.dob ? <FormFeedback>{getString(errors.dob, language)}</FormFeedback> : null}
                            </FormGroup>
                            </Col>
                        </Row> */}
                    
                        {/* <Row>
                            <Col>
                                <FormGroup>
                                    <Label>{getString("age", language)}</Label>
                                    <InputGroup>
                                            <Input disabled={isSubmitting} invalid={errors.age && touched.age} value={values.age} onChange={handleChange} onBlur={handleBlur} name="age" type="select">
                                                {
                                                    [...Array(84).keys()].map(offset =>{
                                                        const age = 16 + offset
                                                        return <option key={age} value={age}>{age}</option>
                                                    })
                                                }
                                            </Input>
                                            <InputGroupAddon addonType="append">
                                                <InputGroupText> yrs </InputGroupText>
                                            </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>{getString("gender", language)}</Label>
                                    <Input disabled={isSubmitting} invalid={errors.gender && touched.gender} value={values.gender} onChange={handleChange} onBlur={handleBlur} name="gender" type="select">
                                            <option>Male</option>
                                            <option>Female</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row> */}
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>{getString("phone", language)}</Label>
                                    <Row>
                                        <Col sm={5} md={5} lg={5} xs={5}>
                                            <Input disabled={isSubmitting} type="select" name="phoneCountryCode" value={values.phoneCountryCode} onChange={handleChange} onBlur={handleBlur}>
                                                <option>+60</option>
                                                <option>+62</option>
                                                <option>+65</option>
                                                <option>+66</option>
                                                <option>+91</option>
                                            </Input>
                                        </Col>
                                        <Col sm={7} md={7} lg={7} xs={7}>
                                            <Input disabled={isSubmitting} type="tel" invalid={errors.phoneNumber && touched.phoneNumber} value={values.phoneNumber} onChange={handleChange} onBlur={handleBlur} name="phoneNumber" />
                                            { errors.phoneNumber && touched.phoneNumber ? <FormFeedback>{getString(errors.phoneNumber, language)}</FormFeedback> : null}
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col>
                                <FormGroup>
                                    <Label>{getString("company", language)}</Label>
                                    <Input disabled={isSubmitting} invalid={errors.company && touched.company} value={values.company} onChange={handleChange} onBlur={handleBlur} name="company" />
                                    { errors.company && touched.company ? <FormFeedback>{getString(errors.company, language)}</FormFeedback> : null}
                                </FormGroup>
                            </Col>
                        </Row> */}
                        {/* <Row>
                            <Col>
                                <FormGroup>
                                    <Label>{getString("nationality", language)}</Label>
                                    <Input disabled={isSubmitting} invalid={errors.nationality && touched.nationality} value={values.nationality} onChange={handleChange} onBlur={handleBlur} name="nationality" type="select">
                                        {
                                            nationalityOptions4.map(nationality => <option key={nationality} value={nationality}>{nationality}</option> )
                                        }
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        {
                            values.nationality === "Others" ?
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Input placeholder={getString("specifyNationality", language)} disabled={isSubmitting} invalid={errors.otherNationality && touched.otherNationality} value={values.otherNationality} onChange={handleChange} onBlur={handleBlur} name="otherNationality" />
                                        { errors.otherNationality && touched.otherNationality ? <FormFeedback>{getString(errors.otherNationality, language)}</FormFeedback> : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                            : null
                        } */}
                        {/* <Row>
                            <Col>
                                <FormGroup>
                                    <Label>{getString("religion", language)}</Label>
                                    <Input disabled={isSubmitting} invalid={errors.religion && touched.religion} value={values.religion} onChange={handleChange} onBlur={handleBlur} name="religion" type="select">
                                        {
                                            religionOptions5.map(religion => <option key={religion} value={religion}>{religion}</option> )
                                        }
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        {
                            values.religion === "Others" ?
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Input placeholder={getString("specifyReligion", language)} disabled={isSubmitting} invalid={errors.otherReligion && touched.otherReligion} value={values.otherReligion} onChange={handleChange} onBlur={handleBlur} name="otherReligion" />
                                        { errors.otherReligion && touched.otherReligion ? <FormFeedback>{getString(errors.otherReligion, language)}</FormFeedback> : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                            : null
                        } */}
                        {/* <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>{getString("prefLang", language)}</Label>
                                            <Input disabled={isSubmitting} type="select" name="prefLang" value={values.prefLang} onChange={handleChange} onBlur={handleBlur}>
                                                {
                                                    prefLangOptions5.map(prefLang => <option key={prefLang} value={prefLang}>{prefLang}</option> )

                                                }
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {
                            values.prefLang === "Others" ?
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Input placeholder={getString("specifyPrefLang", language)} disabled={isSubmitting} invalid={errors.otherPrefLang && touched.otherPrefLang} value={values.otherPrefLang} onChange={handleChange} onBlur={handleBlur} name="otherPrefLang" />
                                        { errors.otherPrefLang && touched.otherPrefLang ? <FormFeedback>{getString(errors.otherPrefLang, language)}</FormFeedback> : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                            : null
                        } */}
                        {/* <Row>
                            <Col>
                                <FormGroup>
                                    <Label>{getString("dietaryPref", language)}</Label>
                                    <Input disabled={isSubmitting} invalid={errors.dietaryPref && touched.dietaryPref} value={values.dietaryPref} onChange={handleChange} onBlur={handleBlur} name="dietaryPref" type="select">
                                        {
                                            dietPrefOptions5.map(dietaryPref => <option key={dietaryPref} value={dietaryPref}>{dietaryPref}</option> )
                                        }
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        {
                            values.dietaryPref === "Others" ?
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Input placeholder={getString("specifyDiet", language)} disabled={isSubmitting} invalid={errors.otherDiet && touched.otherDiet} value={values.otherDiet} onChange={handleChange} onBlur={handleBlur} name="otherDiet" />
                                        { errors.otherDiet && touched.otherDiet ? <FormFeedback>{getString(errors.otherDiet, language)}</FormFeedback> : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                            : null
                        } */}
                        <Row style={{marginTop: 15}}>
                            <Col style={{textAlign: 'right'}}>
                                <Button type="submit" color="primary" disabled={isSubmitting}>{getString("submit", language)}</Button>
                            </Col>
                        </Row>
                    </Form>
                }}
            </Formik>
    }

    return <Container className="app-container">
        <Navbar color="light" light expand="md" className="default-nav">
            <Nav className="mr-auto">
                <NavbarBrand><img height={30} src={logo} alt="logo"/> </NavbarBrand>
            </Nav>
            <Nav>
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText> {renderFlag(language)} </InputGroupText>
                    </InputGroupAddon>
                    <Input disabled={isSubmitting} type="select" value={language} onChange={e => setLanguage(e.target.value)}>
                        {keys(languages).map(value => {
                            const { name = '' } = languages[value]

                            return <option value={value} key={value}>{name} </option>
                        })}                        
                    </Input>
                </InputGroup>
            </Nav>
        </Navbar>
        <Container className="form-container">
            {
                renderForm()
            }
        </Container>
        <LoaderModal isOpen={isSubmitting} language={language} />

    </Container>

}