import React, { useEffect } from 'react'
import { getString } from './utils'

export const Download = ({language}) => {

    useEffect(() => {
        setTimeout(() => {
            window.location.href = "https://www.getknowapp.com/download"
        }, 5000)
    })

    return <div><br/><br/><b>{getString("nextStep", language)}<br/><br/>{getString("redirect", language)} <a href="https://www.getknowapp.com/download">{getString("here", language)}</a></b></div>
}